<template>
  <div v-if="canAccess('doc_enroll_read')||canAccess('doc_enroll_write')||canAccess('doc_enroll_delete')">
    <Dialog v-if="canAccess('doc_enroll_delete') && data.status!=='NBS3'" v-model:visible="confirmDeleteModalDoc"
            :style="{width: '450px'}" header="Confirmaci&oacute;n de registros" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span>Seguro de eliminar registro?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text"
                @click="confirmDeleteModalDoc = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteFile"/>
      </template>
    </Dialog>

    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div v-if="canAccess('doc_enroll_write') && data.status!=='NBS3'">
      <div class="row">
        <div class="p-grid">
          <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
            <span class="p-float-label">
              <InputText
                  id="sequential"
                  v-model="data.sequential"
                  :disabled="true"
              />
              <label class="p-label" for="sequential">Nro. Matrícula</label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
            <span class="p-float-label">
              <InputText
                  id="registration"
                  v-model="data.registration"
                  :disabled="true"
              />
              <label class="p-label" for="registration">
                Fecha de Registro
              </label>
            </span>
          </div>
          <div class="p-field p-fluid p-col-12 p-sm-12 p-md-4">
            <span class="p-float-label">
              <InputText
                  id="referralNote"
                  v-model="data.status"
                  :disabled="true"
              />
              <label class="p-label" for="referralNote">Estado</label>
            </span>
          </div>
        </div>
      </div>
      <hr/>
      <div class="row">
        <h2>Subida de Documentos</h2>
        <FileUpload
            chooseLabel="Buscar"
            upload-label="Subir"
            cancel-label="Cancelar"
            name="files[]"
            :customUpload="true"
            @uploader="onUpload"
            :multiple="true"
            :auto="false"
            accept="image/*,application/pdf,video/mp4"
            file-limit="10"
            :maxFileSize="10000000">
          <template #empty>
            <p>Arrastra documentos para subir información.</p>
          </template>
        </FileUpload>
      </div>
    </div>


    <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white">
      <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 350px;width: 100%; overflow-x: auto;">
        <div class="p-mt-3">
          <DataTable :value="files" responsiveLayout="scroll">
            <Column field="name_file" header="Nombre de Documento"></Column>
            <Column field="created_at" header="Fecha creación"></Column>
            <Column header="Acciones">
              <template #body="slotProps">
                <Button icon="pi pi-download" :title="slotProps.data.name_file"
                        class="p-button-rounded p-button-info p-button-outlined p-mr-2"
                        @click="viewFile(slotProps.data.id)"/>
                <Button v-if="canAccess('doc_enroll_delete') && data.status!=='NBS3'" icon="pi pi-times"
                        class="p-button-rounded p-button-outlined p-button-danger"
                        :title="'Eliminar: '+slotProps.data.name_file" @click="confirmDelete(slotProps.data.id)"/>
              </template>
            </Column>
          </DataTable>
          <div>
            <span v-if="files.length === 0">No existen documentos asociados a la matrícula.</span>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import bouncer from "../../../../helpers/bouncer";
import {mapGetters, mapState} from "vuex";
import documentService from "../../service/document.service";
import {notificationMessages, typeNotification} from "../../../../helpers/consts";

export default {
  mixins: [bouncer],
  name: "DocEnrollmentList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  data() {
    return {
      data: {},
      files: [],
      documentId: 0,
      confirmDeleteModalDoc: false
    }
  },
  methods: {
    onUpload(event) {
      const formData = new FormData();
      for (let file of event.files) {
        formData.append('files[]', file);
      }
      documentService
          .upload(this.data.id, formData)
          .then((response) => {
            this.files = response.data
          })
          .catch(() => {
            this.notification(
                typeNotification.ERROR,
                notificationMessages.error,
                notificationMessages.THERE_WAS_AN_ERROR_FILE
            );
          })

    },
    viewFile(id) {
      documentService
          .view(id)
          .then(async response => {
            const linkView = document.createElement('a');
            linkView.target = "_blank";
            linkView.href = response.data;
            linkView.click();
          })
          .catch((err) => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          });
    },
    confirmDelete(id) {
      this.documentId = id;
      this.confirmDeleteModalDoc = true;
    },
    deleteFile() {
      documentService
          .delete(this.documentId)
          .then(() => this.getDocuments())
          .catch((err) => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          }).finally(() => {
        this.confirmDeleteModalDoc = false
      })
    },
    getDocuments() {
      documentService
          .files(this.data.id)
          .then((response) => {
            this.files = response.data
          })
          .catch((err) => {
            const message = err.response.data;
            let error = this.$utils.formatError(message);
            this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
          })
    },
    map() {
      this.data = this.enroll;
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
  },
  mounted() {
    if (this.canAccess('doc_enroll_write') || this.canAccess('doc_enroll_read')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Gestión operativa', url: "javascript:void(0);"},
        {label: 'Ingreso Documentos', url: "javascript:void(0);"},
      ]);
      this.map();
      this.getDocuments();
    }
  }
}
</script>

<style scoped>
::v-deep(.p-fileupload) {
  .p-fileupload-buttonbar .p-button {
    display: block;
  }

  .p-fileupload-buttonbar {
    display: flex;
  }
}

.p-field {
  padding-top: 15px;
}

.p-grid {
  margin-top: 15px;
}

.p-message-wrapper > span {
  display: none !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  text-align: center !important;
}

.p-datatable table {
  table-layout: auto;
}


thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.p-scrollpanel p {
  padding: .5rem;
  line-height: 1.5;
  margin: 0;
}
</style>
